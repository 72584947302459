<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-key"></i> Regras de Acesso</h5>
                    <btn-refresh @click="atualizar()"></btn-refresh>
                </div>
                <tabela
                    :data="regras"
                    dataKey="regraAcessoId"
                    :globalFilterFields="['areaDescr', 'subAreaDescr', 'codRegraAcesso', 'descricaoRegraAcesso']"
                >
                    <template #botoes>
                        <btn-inserir @atualizar="atualizar()"></btn-inserir>
                    </template>
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <btn-atualizar :regra="slotProps.data" @atualizar="atualizar()"></btn-atualizar>
                            </template>
                        </Column>
                        <Column field="codRegraAcesso" header="Código" :sortable="true">
                            <template #body="slotProps">
                                <span v-if="!$temAcessoView('CTRLACS-RA-04')">{{ slotProps.data.codRegraAcesso }}</span>
                                <span
                                    v-if="$temAcessoView('CTRLACS-RA-04')"
                                    :title="'Nível: ' + slotProps.data.nivel"
                                    >{{ slotProps.data.codRegraAcesso }}</span
                                >
                            </template>
                        </Column>
                        <Column field="descricaoRegraAcesso" header="Descrição" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.descricaoRegraAcesso }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
</template>

<script>
import RegrasServices from './services';
import BtnAtualizar from './BtnAtualizar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnAtualizar,
        BtnInserir,
    },

    data() {
        return {
            regras: null,
        };
    },

    methods: {
        obterRegras() {
            this.$store.dispatch('addRequest');
            RegrasServices.obterTodas().then(response => {
                if (response && response.success) {
                    this.regras = response.data;
                } else {
                    this.regras = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterRegras();
        },
    },

    mounted() {
        this.obterRegras();
    },
};
</script>
